import {
  DefaultBodyType,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";

interface FaqProps {
  clientName: string;
  phoneNumber: string;
  email: string;
  address: string;
  contents: string;
}

const mockFaq = async (
  _: RestRequest<FaqProps>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext,
) => {
  return res(ctx.status(200), ctx.json({}));
};
const mockNoticeList = async (
  _: RestRequest<FaqProps>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext,
) => {
  return res(
    ctx.status(200),
    ctx.json({
      rows: [
        {
          id: 325,
          createdAt: "2023-12-07T00:00:00",
          title: "주식분할 및 주권제출 공고",
          isPriorityExposed: false,
        },
        {
          id: 283,
          createdAt: "2023-11-21T00:00:00",
          title: "신주발행 공고",
          isPriorityExposed: false,
        },
        {
          id: 282,
          createdAt: "2023-11-21T00:00:00",
          title: "신주배정기준일 공고",
          isPriorityExposed: false,
        },
      ],
      count: 3,
    }),
  );
};
const mockNoticeDetail = async (
  _: RestRequest<FaqProps>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext,
) => {
  return res(
    ctx.status(200),
    ctx.json({
      row: {
        content:
          '<p>&nbsp;</p><figure class="image"><img src="https://everon-gatewayserver-files.s3.ap-northeast-2.amazonaws.com/notice/20241030111103-Epit_%EA%B3%B5%EC%A7%801.png"></figure><figure class="image"><img src="https://everon-gatewayserver-files.s3.ap-northeast-2.amazonaws.com/notice/20241030111116-Epit_%EA%B3%B5%EC%A7%802.png"></figure>',
        title: "[안내] E-pit(현대자동차) 충전소 공동이용(로밍서비스) 시행 안내",
        createdAt: "2024-10-30T00:00:00",
        isPriorityExposed: false,
      },
    }),
  );
};
export { mockFaq, mockNoticeList, mockNoticeDetail };
