import React, { Suspense, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { GlobalStyle } from "./styles";
import Loader from "@components/atoms/Loader";
// import Home from "@pages/Home";

const Home = React.lazy(() => import("@pages/Home"));
const GlobalModal = React.lazy(() => import("@components/template/GlobalModal"));
const CorporateSignup = React.lazy(() => import("@pages/CorporateSignup"));
const Notice = React.lazy(() => import("@pages/Notice"));
const NoticeDetail = React.lazy(() => import("@pages/NoticeDetail"));
const IRNotice = React.lazy(() => import("@pages/IRNotice"));
const IRNoticeDetail = React.lazy(() => import("@pages/IRNoticeDetail"));
const Terms = React.lazy(() => import("@pages/Terms"));

const Wrapper = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{children}</>;
};

function App() {
  useEffect(() => {
    if (window.location.pathname === "/chargeMobileWeb/guest/charge") {
      window.location.href = "https://app.everon.co.kr/chargeMobileWeb/guest/charge";
    }
  }, []);

  return (
    <>
      <Wrapper>
        <GlobalStyle />
        <Routes>
          <Route
            path="/"
            element={
              <Suspense fallback={<Loader />}>
                <Home />
              </Suspense>
            }
          />
          <Route
            path="/notice"
            element={
              <Suspense fallback={<Loader />}>
                <Notice />
              </Suspense>
            }
          />
          <Route
            path="/notice/:id"
            element={
              <Suspense fallback={<Loader />}>
                <NoticeDetail />
              </Suspense>
            }
          />
          <Route
            path="/ir-notice"
            element={
              <Suspense fallback={<Loader />}>
                <IRNotice />
              </Suspense>
            }
          />
          <Route
            path="/ir-notice/:id"
            element={
              <Suspense fallback={<Loader />}>
                <IRNoticeDetail />
              </Suspense>
            }
          />
          <Route
            path="/terms/records"
            element={
              <Suspense fallback={<Loader />}>
                <Terms />
              </Suspense>
            }
          />
          <Route
            path="/corporate/signup"
            element={
              <Suspense fallback={<Loader />}>
                <CorporateSignup />
              </Suspense>
            }
          />
          <Route
            path="/*"
            element={
              <Suspense fallback={<Loader />}>
                <Home />
              </Suspense>
            }
          />
        </Routes>
      </Wrapper>
      <Suspense fallback={<Loader />}>
        <GlobalModal />
      </Suspense>
    </>
  );
}

export default App;
