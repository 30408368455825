import styled from 'styled-components';
import { colors } from "@styles/index";

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.3); /* Slightly transparent background */
  z-index: 9999; /* Brings loader to the front */
`;

const StyledLoader = styled.div`
  width: 60px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side, ${colors.PRIMARY} 90%, #0000);
  background: 
    var(--_g) 0% 50%,
    var(--_g) 50% 50%,
    var(--_g) 100% 50%;
  background-size: calc(100% / 3) 50%;
  animation: l3 1s infinite linear;

  @keyframes l3 {
    20% { background-position: 0% 0%, 50% 50%, 100% 50%; }
    40% { background-position: 0% 100%, 50% 0%, 100% 50%; }
    60% { background-position: 0% 50%, 50% 100%, 100% 0%; }
    80% { background-position: 0% 50%, 50% 50%, 100% 100%; }
  }
`;

function Loader() {
  return (
    <LoaderWrapper>
      <StyledLoader />
    </LoaderWrapper>
  );
}

export default Loader;
