import { rest } from "msw";
import {
  mockFaq,
  mockNoticeDetail,
  mockNoticeList,
} from "./resolvers/mockApisBook";

export const handlers = [
  rest.post("/homepage/installation", mockFaq),
  rest.get("/homepage/notices", mockNoticeList),
  rest.get("/homepage/notices/*", mockNoticeDetail),
];
